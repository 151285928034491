<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'
import Multiselect from 'vue-multiselect'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import { BASE_IMAGE_URL } from '@src/config/configs'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { VueEditor, Quill } from 'vue2-editor'
import ImageResize from 'quill-image-resize-module'

Quill.register('modules/imageResize', ImageResize)

/**
 * Contents component
 */
export default {
  page: {
    title: 'Nearby local',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    Multiselect,
    vueDropzone: vue2Dropzone,
    VueEditor,
    Loading,
  },

  data() {
    return {
      editorSettings: {
        modules: {
          imageResize: {},
        },
      },

      isLoading: false,
      baseImageUrl: BASE_IMAGE_URL,
      form: {
        status: true,
        title: '',
        subtitle : '',
        price : '',
        address : '',
        open_place :  '',
        close_place :  '',
        summary :  '',
        latitude : '',
        longitude : '',
        image: [],
        new_image: [],
        inputtag: [],    
        
      },

      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 340,
        maxFilesize: 5,
        parallelUploads: 50,
        paramName: 'images',
        uploadMultiple: true,
        acceptedFiles: 'image/*',      
        addRemoveLinks: true,
        acceptedFiles: '.jpeg,.jpg,.png,.gif',
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME",
      },
      ismanuallyadd: false,
      isEdit: false,
    }
  },
  validations: {
    form: {
      title: {
        required,
      },     
      subtitle: {
        required,
      },
      price: {
        required,
      },
      address: {
        required,
      },
      open_place: {
        required,
      },
      close_place: {
        required,
      }, 
      summary: {
        required,
      },
      latitude: {
        required,
      },
      longitude: {
        required,
      }
    },
  },
  mounted() {
    const id = this.$route.params.id
    this.initialUpdateItem(id)
  },
  methods: {
    doAjax() {
      this.isLoading = true
      // simulate AJAX
    },
    onCancel() {
      this.isLoading = false

      // console.log('User cancelled the loader.')
    },

    vfileAdded(file) {

      this.form.new_image = file

      this.ismanuallyadd = false
    },
    vfilemanuallyAdded(file) {
      this.ismanuallyadd = true
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },

    async removeImage(picId){
      const request = axios.get(`/api/place/removeImage/${this.$route.params.id}/${picId}`)
      
      await request
        .then(async (result) => {
          console.log("r =>" + result)
          this.onCancel()
          this.$router.go(0)
        })
        .catch((err) => {
          this.onCancel()
          this.$toast.error(err.response.data.error.message)
        })
    },

  async formSubmit() {
      this.doAjax()
      let body = {
        title : this.form.title,
        subtitle : this.form.subtitle,
        price : this.form.price,
        address : this.form.address,
        open_place : this.form.open_place,
        close_place : this.form.close_place,
        summary : this.form.summary,
        latitude : this.form.latitude,
        longitude : this.form.longitude     
      }
      
      const data = new FormData()
      if (!this.ismanuallyadd) {
        for (const i of Object.keys(this.form.new_image)) {

          data.append('image', this.form.new_image[i])
        }
      }

      data.append('data', JSON.stringify(body))

      const paramsId = this.$route.params.id
      const request = axios.post(`/api/place/edit/${paramsId}`, data)

      await request
        .then(async (result) => {
          console.log("r =>" + result)
          this.onCancel()
          this.$toast.success('Saved successfully')
          this.$router.push('/place')
        })
        .catch((err) => {
          this.onCancel()
          this.$toast.error(err.response.data.error.message)
        })
    },


    async initialUpdateItem(id) {
      if (!id) return
       console.log("log set =>" + id)
      await axios.get(`api/place/edit/${id}`).then((res) => {
        console.log(res)
        this.form = { ...this.form, ...res.data }
     
      })
      
    },

  },
}
</script>
<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">Nearby local</h4>
      </div>

      <div class="row mt-2" style="height:100%; width:100%;">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <b-form class="form-horizontal" @submit.prevent="formSubmit">
                <div class="row center">
                  <div class="col-7">
                    <b-form-group label-cols-lg="0">
                      <label>Title</label>
                      <b-form-input
                        id="title"
                        v-model="$v.form.title.$model"
                        placeholder="Enter title..."
                        name="title"
                        type="text"
                        :state="validateState('title')"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required field</b-form-invalid-feedback
                      >
                    </b-form-group>
                    <b-form-group label-cols-lg="0">
                      <label>Sub title</label>
                      <b-form-input
                        id="subtitle"
                        v-model="form.subtitle "
                        placeholder="Enter subtitle "
                        name="subtitle"
                        type="text"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-lg="0">
                      <label>Price</label>
                      <b-form-input
                        id="price"
                        v-model="form.price "
                        placeholder="Enter price "
                        name="price"
                        type="number"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-lg="0">
                      <label>Address</label>
                      <b-form-input
                        id="address"
                        v-model="form.address "
                        placeholder="Enter address "
                        name="address"
                        type="text"
                      ></b-form-input>
                    </b-form-group>
                    <b-row>
                      <b-col cols="6" class="mb-3">
                        <label class="mr-4">Open</label>
                        <b-form-timepicker v-model="form.open_place" locale="en"></b-form-timepicker>

                      </b-col>
                      <b-col cols="6" class="mb-3">
                        <label class="mr-4">Close</label>
                        <b-form-timepicker v-model="form.close_place" locale="en"></b-form-timepicker>
                      </b-col>
                    </b-row>
                    <b-form-group label-cols-lg="0">
                      <label>Summary</label>
                      <b-form-textarea
                        id="textarea"
                        v-model="form.summary"
                        placeholder="Enter summary..."
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>

                    <b-form-group label-cols-lg="0">
                      <label>Image</label>
                    <div>
                      <b-card-group deck>
                      <div v-for="item in this.form.image" :key="item._id">
                      <b-card                     
                       :img-src="`${baseImageUrl}/${item.url}`"
                       img-alt="Image"
                       img-top
                       tag="article"
                       style="max-width: 10rem;"
                       class="mb-2"
                      >
                      <b-button @click="removeImage(item._id)" variant="danger">Delete</b-button>
                      </b-card>
                      </div>

                    </b-card-group>

                      </div>
                    </b-form-group>


                    <b-form-group  label-cols-lg="0">
                      <label>
                        Upload Image
                        <small class="color-text-small"
                          >Width 500 x Height 500. Just only one.</small
                        >
                      </label>
  
                      <vue-dropzone 
                        ref="myVueDropzone" 
                        id="form-image" 
                        accepted-files="image/*"
                        :options="dropzoneOptions"
                        :useCustomSlot="true" 
                        @vdropzone-success-multiple="vfileAdded" 
                      ></vue-dropzone>
                    </b-form-group>                

                    <b-form-group label-cols-lg="0">
                      <label>Latitude</label>
                      <b-form-input
                        id="latitude"
                        v-model="form.latitude "
                        placeholder="Enter latitude "
                        name="latitude"
                        type="number"
                        step="any"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-lg="0">
                      <label>Longitude</label>
                      <b-form-input
                        id="longitude"
                        v-model="form.longitude "
                        placeholder="Enter longitude "
                        name="longitude"
                        type="number"
                        step="any"
                      ></b-form-input>                  
                    </b-form-group>
        
                    <loading
                      :active.sync="isLoading"
                      :can-cancel="false"
                      :on-cancel="onCancel"
                      :is-full-page="true"
                    ></loading>

   
                    <b-form-group>
                      <router-link to="/place">
                        <button
                          type="button"
                          class="btn btn-danger float-right ml-2"
                          >Cancel</button
                        >
                      </router-link>
                      <button type="submit" class="btn btn-primary float-right"
                        >Save</button
                      >
                    </b-form-group>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" scope>
.unactiveClass {
  border: 1px solid #5269f785;
  background: #5269f785;
}
</style>
